<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据审核</el-breadcrumb-item>
      <el-breadcrumb-item>审核记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 超级管理员 -->
      <div class="progress" v-if="roleName == '超级管理员'">
        <el-tabs type="border-card">
          <el-tab-pane>
            <template #label>数据审核记录</template>
            <span>资料审核</span>
            <el-table :data="document" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="册数" prop="volume"></el-table-column>
              <el-table-column label="文件材料名称" prop="documentContentName"></el-table-column>
              <el-table-column label="资料说明" prop="documentDescription"></el-table-column>
              <el-table-column label="资料完成情况说明" prop="documentCompleteDescription"></el-table-column>
              <el-table-column label="总数" prop="totalNum"></el-table-column>
              <el-table-column label="编制数" prop="organizationNum"></el-table-column>
              <el-table-column label="监理签认数" prop="supervisorNum"></el-table-column>
              <el-table-column label="设计签认数" prop="designNum"></el-table-column>
              <el-table-column label="建设单位签认数" prop="constructionNum"></el-table-column>
              <el-table-column label="存档原件数" prop="archiveOriginalNum"></el-table-column>
              <el-table-column label="存档复印数" prop="archiveCopyNum"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因">
                <template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="docSizeChange" @current-change="docCurChange"
                :current-page="docQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="docTotal"></el-pagination>
            </el-config-provider>
            <span>实验审核</span>
            <el-table :data="experiment" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="工程名称" prop="projectName"></el-table-column>
              <el-table-column label="材料及实体名称" prop="materialEntityName"></el-table-column>
              <el-table-column label="检测项目" prop="testItem"></el-table-column>
              <el-table-column label="现场进度工程量" prop="progressQuantity"></el-table-column>
              <el-table-column label="实际检测数量" prop="actualRequiredQuantity"></el-table-column>
              <el-table-column label="取消检测？" prop="obtainedQuantity" v-slot="scope">
                {{
                  scope.row.obtainedQuantity == 1 ? "是" : "否"
                }}
              </el-table-column>
              <el-table-column label="是否合格？" prop="passQuantity" v-slot="scope">
                {{ scope.row.passQuantity == 1 ? "是" : "否" }}
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                </template>
              </el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="原因">
                <template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="expSizeChange" @current-change="expCurChange"
                :current-page="expQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="expTotal"></el-pagination>
            </el-config-provider>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>excel上传记录</template>
            <span>资料表</span>
            <el-table :data="documentExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="docESizeChange" @current-change="docECurChange"
                :current-page="docEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="docETotal">
              </el-pagination>
            </el-config-provider>
            <span>实验表</span>
            <el-table :data="experimentExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="expESizeChange" @current-change="expECurChange"
                :current-page="expEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="expETotal">
              </el-pagination>
            </el-config-provider>
            <span>合约表</span>
            <el-table :data="baseExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange"
                :current-page="baseQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="baseTotal">
              </el-pagination>
            </el-config-provider>
            <span>甩项表</span>
            <el-table :data="jiltExcel" border stripe style="margin-bottom: 15px">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="excel名称" prop="excelName"></el-table-column>
              <el-table-column label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 0">待审核</span>
                  <span v-if="scope.row.status == 1">已通过</span>
                  <span v-if="scope.row.status == 2">不通过</span>
                  <span v-if="scope.row.status == 3">无效</span>
                </template>
              </el-table-column>
              <el-table-column label="上报员" prop="reportUserId"></el-table-column>
              <el-table-column label="审核时间"><template v-slot="scope">{{
                  DateFormat(scope.row.updateTime)
                }}</template>
              </el-table-column>
              <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
              <el-table-column label="原因"><template v-slot="scope"><span>{{
                    scope.row.status == 2 ? scope.row.reason : "无"
                  }}</span></template>
              </el-table-column>
            </el-table>
            <el-config-provider :locale="locale">
              <el-pagination @size-change="jiltSizeChange" @current-change="jiltCurChange"
                :current-page="jiltQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="jiltQuery.pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="jiltTotal">
              </el-pagination>
            </el-config-provider>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 施工主管 -->
      <div class="progress" v-if="roleName == '施工主管' || admins.indexOf(roleName) > 0">
        <div class="report">
          <el-row>
            <el-col :span="17"><span class="title">施工主管-进度表审核记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入名称" v-model="proQuery.query" @keyup.enter="getProgress()" clearable
                @clear="getProgress()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getProgress()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="progress" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="进度单编号" prop="progressNo"></el-table-column>
            <el-table-column label="起桩号" prop="beginPile"></el-table-column>
            <el-table-column label="止桩号" prop="endPile"></el-table-column>
            <el-table-column label="本次完成工程量" prop="completeQuantity"></el-table-column>
            <el-table-column label="项目编号" prop="itemNo"></el-table-column>
            <el-table-column label="项目名称" prop="itemName"></el-table-column>
            <el-table-column label="合价" prop="combinedPrice"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="上报时间" min-width="120px"><template
                v-slot="scope"><span>{{ DateFormat(scope.row.createTime) }}</span></template>
            </el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="proSizeChange" @current-change="proCurChange" :current-page="proQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="proQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="proTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">excel审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDateQus">
              </el-date-picker>
              <el-select @change="getProgressExcel" class="select2" size="small" v-model="proEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="7">
							<el-input placeholder="请输入excel表名称" v-model="proEQuery.query"
								@keyup.enter="getProgressExcel()" clearable @clear="getProgressExcel()">
								<template #append>
									<el-button icon="el-icon-search" @click="getProgressExcel()"></el-button>
								</template>
							</el-input>
						</el-col> -->
          </el-row>
          <el-table :data="progressExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="proESizeChange" @current-change="proECurChange"
              :current-page="proEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="proEQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="proETotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 试验主管 -->
      <div class="experiment" v-if="roleName == '试验主管' || admins.indexOf(roleName) > 0">
        <div class="export">
          <el-row>
            <el-col :span="17" class="colLeft"><span class="title">实验主管-试验检测表审核记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入试验名称" v-model="expQuery.query" @keyup.enter="getExperiment()" clearable
                @clear="getExperiment()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getExperiment()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="experiment" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="工程名称" prop="projectName"></el-table-column>
            <el-table-column label="材料及实体名称" prop="materialEntityName"></el-table-column>
            <el-table-column label="检测项目" prop="testItem"></el-table-column>
            <el-table-column label="现场进度工程量" prop="progressQuantity"></el-table-column>
            <el-table-column label="实际检测数量" prop="actualRequiredQuantity"></el-table-column>
            <el-table-column label="取消检测？" prop="obtainedQuantity" v-slot="scope">
              {{
                scope.row.obtainedQuantity == 1 ? "是" : "否"
              }}
            </el-table-column>
            <el-table-column label="是否合格？" prop="passQuantity" v-slot="scope">
              {{ scope.row.passQuantity == 1 ? "是" : "否" }}
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="原因">
              <template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expSizeChange" @current-change="expCurChange" :current-page="expQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="expQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="expTotal"></el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">实验主管-excel审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getExperimentExcel" class="select2" size="small" v-model="expEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="7">
							<el-input placeholder="请输入excel表名称" v-model="expEQuery.query"
								@keyup.enter="getExperimentExcel()" clearable @clear="getExperimentExcel()">
								<template #append>
									<el-button icon="el-icon-search" @click="getExperimentExcel()"></el-button>
								</template>
							</el-input>
						</el-col> -->
          </el-row>
          <el-table :data="experimentExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expESizeChange" @current-change="expECurChange"
              :current-page="expEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="expETotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
      <!-- 试验主管 -->
      <div class="experiment" v-if="roleName == '试验主管' || admins.indexOf(roleName) > 0">
        <!-- 不合格表审核记录 -->
        <div class="excelQus">
          <el-row>
            <el-col :span="17" class="colLeft"><span class="title">实验主管-excel不合格表审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDateQus">
              </el-date-picker>
              <el-select @change="getExperimentExcelQus" class="select2" size="small" v-model="expEQueryQus.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="7">
							<el-input placeholder="请输入excel表名称" v-model="expEQueryQus.query"
								@keyup.enter="getExperimentExcelQus()" clearable @clear="getExperimentExcelQus()">
								<template #append>
									<el-button icon="el-icon-search" @click="getExperimentExcelQus()"></el-button>
								</template>
							</el-input>
						</el-col> -->
          </el-row>
          <el-table :data="experimentExcelQus" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template></el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="expESizeChangeQus" @current-change="expECurChangeQus"
              :current-page="expEQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="expEQueryQus.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="expETotalQus">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 资料主管 -->
      <div class="document" v-if="roleName == '资料主管' || admins.indexOf(roleName) > 0">
        <div class="report">
          <el-row>
            <el-col :span="17"><span class="title">资料主管-资料数据审核记录</span></el-col>
            <el-col :span="7">
              <el-input placeholder="请输入资料名称" v-model="docQuery.query" @keyup.enter="getDocument()" clearable
                @clear="getDocument()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocument()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="document" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="册数" prop="volume"></el-table-column>
            <el-table-column label="文件材料名称" prop="documentContentName"></el-table-column>
            <el-table-column label="资料说明" prop="documentDescription"></el-table-column>
            <el-table-column label="资料完成情况说明" prop="documentCompleteDescription"></el-table-column>
            <el-table-column label="总数" prop="totalNum"></el-table-column>
            <el-table-column label="编制数" prop="organizationNum"></el-table-column>
            <el-table-column label="监理签认数" prop="supervisorNum"></el-table-column>
            <el-table-column label="设计签认数" prop="designNum"></el-table-column>
            <el-table-column label="建设单位签认数" prop="constructionNum"></el-table-column>
            <el-table-column label="存档原件数" prop="archiveOriginalNum"></el-table-column>
            <el-table-column label="存档复印数" prop="archiveCopyNum"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因">
              <template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docSizeChange" @current-change="docCurChange" :current-page="docQuery.pagenum"
              :page-sizes="[5, 10, 20]" :page-size="docQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="docTotal"></el-pagination>
          </el-config-provider>
        </div>
        <!-- excel -->
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">资料主管-excel审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getDocumentExcel" class="select2" size="small" v-model="docEQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="docEQuery.query" @keyup.enter="getDocumentExcel()" clearable
                @clear="getDocumentExcel()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocumentExcel()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="documentExcel" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docESizeChange" @current-change="docECurChange"
              :current-page="docEQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="docETotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
      <div class="documentQus" v-if="roleName == '资料主管' || admins.indexOf(roleName) > 0">
        <div class="excelQus">
          <el-row>
            <el-col :span="17"><span class="title">资料主管-excel问题表审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDateQus">
              </el-date-picker>
              <el-select @change="getDocumentExcelQus" class="select2" size="small" v-model="docEQueryQus.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-input placeholder="请输入excel表名称" v-model="docEQueryQus.query" @keyup.enter="getDocumentExcelQus()"
                clearable @clear="getDocumentExcelQus()">
                <template #append>
                  <el-button icon="el-icon-search" @click="getDocumentExcelQus()"></el-button>
                </template>
              </el-input>
            </el-col>
          </el-row>
          <el-table :data="documentExcelQus" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template></el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="docESizeChangeQus" @current-change="docECurChangeQus"
              :current-page="docEQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="docEQueryQus.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="docETotalQus">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>

      <!-- 合约员 excel审核记录 -->
      <div class="construct" v-if="roleName == '合约主管' || admins.indexOf(roleName) > 0">
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">合约主管-excel审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getBaseList" class="select2" size="small" v-model="baseQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-table :data="baseList" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>

            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange"
              :current-page="baseQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="baseTotal">
            </el-pagination>
          </el-config-provider>
        </div>
        <div class="excel">
          <el-row>
            <el-col :span="17" class="colLeft">
              <span class="title">合约主管-excel审核记录</span>
              <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
                range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
                @change="getDate">
              </el-date-picker>
              <el-select @change="getJilt" class="select2" size="small" v-model="jiltQuery.flag">
                <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-table :data="jilt" border stripe style="margin-bottom: 15px">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="excel名称" prop="excelName"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">不通过</span>
                <span v-if="scope.row.status == 3">无效</span>
              </template>
            </el-table-column>
            <el-table-column label="上报员" prop="reportUserId"></el-table-column>
            <el-table-column label="审核时间"><template v-slot="scope">{{
                DateFormat(scope.row.updateTime)
              }}</template>
            </el-table-column>
            <el-table-column label="审核主管" prop="reviewUserId"></el-table-column>
            <el-table-column label="原因"><template v-slot="scope"><span>{{
                  scope.row.status == 2 ? scope.row.reason : "无"
                }}</span></template>
            </el-table-column>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination @size-change="jiltSizeChange" @current-change="jiltCurChange"
              :current-page="jiltQuery.pagenum" :page-sizes="[5, 10, 20]" :page-size="jiltQuery.pagesize"
              layout="total, sizes, prev, pager, next, jumper" :total="jiltTotal">
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  import dateFormatFun from "../../plugins/dateFormat";

  export default {
    data() {
      return {
        admins: ["超级管理员", "公司管理员", "项目负责人", "公司负责人"],

        progress: [],
        proQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        proTotal: 0,
        progressExcel: [],
        proEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        proETotal: 0,

        experiment: [],
        expQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        expTotal: 0,
        experimentExcel: [],
        expEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        expETotal: 0,
        // 试验主管不合格表审核记录
        experimentExcelQus: [],
        expEQueryQus: {
          flag: 0,
          startTime: "",
          endTime: "",

          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        expETotalQus: 0,

        document: [],
        docQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        docTotal: 0,
        documentExcel: [],
        docEQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        dateType: [{
            id: 0,
            status: "全部",
          },
          {
            id: 1,
            status: "近1周",
          },
          {
            id: 2,
            status: "近1月",
          },
          {
            id: 3,
            status: "近3月",
          },
        ],
        dateValue: [null, null],
        dateValueQus: [null, null],
        docETotal: 0,

        // 资料问题表审核数据
        documentExcelQus: [],
        docEQueryQus: {
          flag: 0,
          startTime: "",
          endTime: "",

          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        docETotalQus: 0,

        baseList: [],
        baseQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        baseTotal: 0,
        jilt: [],
        jiltQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        jiltTotal: 0,

        roleName: "",
      };
    },
    setup() {
      return {
        locale: zhCn,
      };
    },
    mounted() {
      this.roleName = window.sessionStorage.getItem("roleName");
      if (this.roleName == "超级管理员") {
        this.getAllCheck();
      }
      if (this.roleName == "资料主管") {
        this.getDocument();
        this.getDocumentExcel();
        this.getDocumentExcelQus();
      }
      if (this.roleName == "试验主管") {
        this.getExperiment();
        this.getExperimentExcel();
        this.getExperimentExcelQus();
      }
      if (this.roleName == "施工主管") {
        this.getProgress();
        this.getProgressExcel();
      }
      if (this.roleName == "合约主管") {
        this.getBaseList();
        this.getJilt();
      }
    },
    computed: {
      DateFormat() {
        return function(date) {
          return dateFormatFun(date, 2);
        };
      },
    },
    methods: {
      getDate(e) {
        let obj;
        let fun;
        let obj1;
        let fun1;
        if (this.roleName == "资料主管") {
          obj = this.docEQuery;
          fun = this.getDocumentExcel;
        } else if (this.roleName == "试验主管") {
          obj = this.expEQuery;
          fun = this.getExperimentExcel;
        } else if (this.roleName == "施工主管") {

        } else if (this.roleName == "合约主管") {
          obj = this.baseQuery;
          fun = this.getBaseList
          obj1 = this.jiltQuery;
          fun1 = this.getJilt
        }
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
          obj1.startTime = dateFormatFun(e[0], 2);
          obj1.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
          obj1.startTime = "";
          obj1.endTime = "";
        }
        fun();
        fun1()
      },

      getDateQus(e) {
        let obj;
        let fun;
        if (this.roleName == "资料主管") {
          obj = this.docEQueryQus;
          fun = this.getDocumentExcelQus;
        } else if (this.roleName == "试验主管") {
          obj = this.expEQueryQus;
          fun = this.getExperimentExcelQus;
        } else if (this.roleName == "施工主管") {
          obj = this.proEQuery;
          fun = this.getProgressExcel;
        } else if (this.roleName == "合约主管") {}
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
        }
        fun();
      },
      //获取全部审核数据
      async getAllCheck() {
        this.getDocument();
        this.getDocumentExcel();
        this.getExperiment();
        this.getExperimentExcel();
        this.getProgress();
        this.getProgressExcel();
        this.getBaseList();
        this.getJilt();
        this.getDocumentExcelQus();
        this.getExperimentExcelQus();
      },
      // 进度表
      async getProgress() {
        this.proQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "progressSupervisor/getReviewedProgress", {
            params: this.proQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.progress = res.data.list;
        this.proETotal = res.data.total;
      },
      proSizeChange(newSize) {
        this.proQuery.pagesize = newSize;
        this.getProgress();
      },
      proCurChange(newPage) {
        this.proQuery.pagenum = newPage;
        this.getProgress();
      },
      async getProgressExcel() {
        this.proEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "progressSupervisor/getReviewedProgressExcel", {
            params: this.proEQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.progressExcel = res.data.list;
        this.proETotal = res.data.total;
      },
      proESizeChange(newSize) {
        this.proEQuery.pagesize = newSize;
        this.getProgressExcel();
      },
      proECurChange(newPage) {
        this.proEQuery.pagenum = newPage;
        this.getProgressExcel();
      },
      // 基础清单表
      async getBaseList() {
        this.baseQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractSupervisor/getReviewedBaseExcel", {
            params: this.baseQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.baseList = res.data.list;
        this.baseTotal = res.data.total;
      },
      baseSizeChange(newSize) {
        this.baseQuery.pageSize = newSize;
        this.getBaseList();
      },
      baseCurChange(newPage) {
        this.baseQuery.pageNum = newPage;
        this.getBaseList();
      },
      // 甩项工程量
      async getJilt() {
        this.jiltQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractSupervisor/getReviewedJiltExcel", {
            params: this.jiltQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.jilt = res.data.list;
        this.jiltTotal = res.data.total;
      },
      jiltSizeChange(newSize) {
        this.jiltQuery.pageSize = newSize;
        this.getJilt();
      },
      jiltCurChange(newPage) {
        this.jiltQuery.pageNum = newPage;
        this.getJilt();
      },
      // 试验检测表
      async getExperiment() {
        this.expQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimentSupervisor/getReviewedExperiment", {
            params: this.expQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experiment = res.data.list;
        this.expTotal = res.data.total;
      },
      expSizeChange(newSize) {
        this.expQuery.pagesize = newSize;
        this.getExperiment();
      },
      expCurChange(newPage) {
        this.expQuery.pagenum = newPage;
        this.getExperiment();
      },
      async getExperimentExcel() {
        this.expEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimentSupervisor/getReviewedExperimentExcel", {
            params: this.expEQuery,
          }
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experimentExcel = res.data.list;
        this.expETotal = res.data.total;
      },
      expESizeChange(newSize) {
        this.expEQuery.pagesize = newSize;
        this.getExperimentExcel();
      },
      expECurChange(newPage) {
        this.expEQuery.pagenum = newPage;
        this.getExperimentExcel();
      },
      // 试验检测不合格表
      async getExperimentExcelQus() {
        this.expEQueryQus.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimentSupervisor/getReviewedExperimentNoPassExcel", {
            params: this.expEQueryQus,
          }
        );
        console.log(res + "11111");
        if (res.code !== 200) return this.$message.error(res.msg);

        console.log("试验主管-excel不合格表审核记录", res);
        this.experimentExcelQus = res.data.list;
        this.expETotalQus = res.data.total;
      },
      expESizeChangeQus(newSize) {
        this.expEQueryQus.pagesize = newSize;
        this.getExperimentExcelQus();
      },
      expECurChangeQus(newPage) {
        this.expEQueryQus.pagenum = newPage;
        this.getExperimentExcelQus();
      },
      // 资料表
      async getDocument() {
        this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documentSupervisor/getReviewedDocument", {
            params: this.docQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.document = res.data.list;
        this.docTotal = res.data.total;
      },
      docSizeChange(newSize) {
        this.docQuery.pagesize = newSize;
        this.getDocument();
      },
      docCurChange(newPage) {
        this.docQuery.pagenum = newPage;
        this.getDocument();
      },
      async getDocumentExcel() {
        this.docEQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documentSupervisor/getReviewedDocumentExcel", {
            params: this.docEQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.documentExcel = res.data.list;
        this.docETotal = res.data.total;
      },
      docESizeChange(newSize) {
        this.docEQuery.pagesize = newSize;
        this.getDocumentExcel();
      },
      docECurChange(newPage) {
        this.docEQuery.pagenum = newPage;
        this.getDocumentExcel();
      },
      // 资料问题表审核数据

      async getDocumentExcelQus() {
        this.docEQueryQus.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documentSupervisor/getReviewedProblemDocumentExcel", {
            params: this.docEQueryQus,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);

        console.log("资料主管-excel问题表审核记录", res);
        this.documentExcelQus = res.data.list;
        this.docETotalQus = res.data.total;
      },
      docESizeChangeQus(newSize) {
        this.docEQueryQus.pagesize = newSize;
        this.getDocumentExcelQus();
      },
      docECurChangeQus(newPage) {
        this.docEQueryQus.pagenum = newPage;
        this.getDocumentExcelQus();
      },
    },
  };
</script>

<style lang="less" scoped>
  .title {
    font-size: 14px;
    color: #666;
    display: block;
    margin-right: 20px;
  }

  .colLeft {
    display: flex;
    align-items: center;
  }

  .select2 {
    width: 120px;
    margin-left: 20px;
  }
</style>
